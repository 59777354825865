import {useState, useEffect} from 'react';
import './App.css';
import {fetchAnswer, fetchRecentQuestions} from './app_repository';


function MainContent() {
    return (

        <div className="App">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
            <header className="headerSection">
                <h2 className="header">Yahoo Answers 2.0</h2>
                <p>Do you not know where to have your life questions answered now that yahoo answers is gone? Yahoo
                    Answers 2.0 uses over a million original question and answers to recreate this beloved gem. So you
                    can
                    now ask your burning life questions here instead. </p>
                <p>This website was made with love but no affiliation with Yahoo Inc what so ever</p>
            </header>
            <AskQuestion></AskQuestion>
        </div>
    );

    function AskQuestion() {
        const [textAreaVal, setTextAreaVal] = useState('');
        const [saveQ, setSaveQ] = useState(true);
        const [question, setQuestion] = useState('');
        const [answer, setAnswer] = useState('');
        const [historyQA, addQA] = useState([]);
        const [fetching, setFetching] = useState(false);

        async function handleSubmit(e) {
            console.log(JSON.stringify({"question": textAreaVal}));

            e.preventDefault();
            setFetching(true);
            let a = await fetchAnswer(textAreaVal,saveQ);

            setQuestion(textAreaVal);
            setAnswer(a);
            setFetching(false);

            let nextQA = historyQA.slice();
            nextQA.push({"q": question, "a": answer});

            addQA(nextQA);

            for (let qa of historyQA) {
                console.log(`saved. ${qa["q"]}`);
            }


        }


        return <section>
            <form onSubmit={handleSubmit}>
              <textarea
                  rows="8"
                  value={textAreaVal}
                  placeholder="Search..."
                  onChange={v => setTextAreaVal(v.target.value)}
              />
                <br/>
                <input type="checkbox" id="saveQ" name="saveQ" checked={saveQ}
                       onChange={v => setSaveQ(!saveQ)}
                />
                <label htmlFor="saveQ"> Save Question</label>

                <br/>

                <button type="submit" disabled={fetching}  class="submit-btn">
                    {/*<i class="material-icons left">check</i> */}
                    Submit
                </button>
                <p></p>

                {/* spinner */}
                {fetching == true &&
                    <div className="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                }

                {/* answer */}
                {answer != "" &&
                    <div>
                        <br/>
                        <div className="qa-container">
                            <img src="/party_boi.jpg" alt="avatar" class="user-avatar"/>
                            <span class="user-name"><p>You</p></span>
                            <p>{question}</p>
                        </div>
                        <br/>
                        <div className="qa-container">
                            <img src="/feed_me_data.jpg" alt="avatar" class="user-avatar"/>
                            <span className="user-name"><p>YA 2.0</p></span>
                            <p>{answer}</p>
                        </div>
                    </div>
                }
            </form>

        </section>
    }
}


function RecentlyAskedSection() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const baseUrl = process.env.REACT_APP_API_URL

        const url = `${baseUrl}/qa`;
        try {
            const response = await fetch(url); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error(`Error fetching recent questions:\n${error.message}`);
        }
    };

    return (
        <section>
            <h6>Recently Asked Questions</h6>
            {Array.from(data, q => <details>
                <summary>{q['user_question']}</summary>{q['llm_answer']}
            </details>)}

        </section>
    );

}

export default function App() {
    return <div className="pageLayout">
        <div></div>
        <div className="mainContent"><MainContent/></div>
        <div className="sideContent"><RecentlyAskedSection/></div>
        <div></div>
    </div>;
}
