const baseUrl = process.env.REACT_APP_API_URL


export async function fetchAnswer(question, save) {
  const url = `${baseUrl}/qa`;

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ question: question, save_question: save}),
      headers: {"Content-Type": "application/json", "Access-Control-Allow-Origin": "*"}
    });

    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    return json['llm_response']

  } catch (error) {
     console.error(`Error fetching answer:\n${error.message}`);
  }
}

export async function fetchRecentQuestions() {
  const url = `${baseUrl}/qa`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {"Content-Type": "application/json", "Access-Control-Allow-Origin": "*"}
    });

    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    // console.log(json);
    return json;

  } catch (error) {
    console.error(`Error fetching recent questions:\n${error.message}`);
  }
}
